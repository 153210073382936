<router-outlet></router-outlet>
<div class="modal fade" id="zoomPhoto" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" id="zoomPhoto_close" class="close clsCloseBtn" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="clsZoomPhoto_img">
                    <img id="zoomPhoto_img" alt="Casa Bazaar - Zoom Size" onError="this.src='assets/image/empty/default.webp'"  />
                </div>
            </div>
        </div>
    </div>
</div>
