import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';

class Config {
  private encCode: number = 198;

  // apiURL: string = 'https://kosmonk.com/casabazar_admin/api/v2';
  // hostURL: string = 'http://localhost:4200';
  // apiAssetsURL: string = 'https://kosmonk.com/casabazar_admin/public';

  apiURL: string = 'https://casabazaar.in/casabazar_admin/api/v2';
  // hostURL: string = 'http://localhost:4200';
  hostURL: string = window.location.origin;
  apiAssetsURL: string = 'https://casabazaar.in/casabazar_admin/public';

  vendorURL: string = "Vendor";
  private vendorCode = '000X';
  private userCode = '111X';
  private categoryCode = '189X';
  private brandCode = '101X';
  private userToken = localStorage.getItem(this.userCode)?.toString();
  private paymentGetwayKey = 'rzp_test_rhHTWi8fnS7f9q';
  // private paymentGetwayKey = 'rzp_live_7MiiJObpUQDvtf';

  appSettings: any = {
    white_logo: "assets/image/logo/logo_on_whiteBG.webp",
    black_logo: "assets/image/logo/logo_on_blackBG.webp",
  }

  paymentOption(prefill: any, amount: number, handler: Function): any {
    $("#newScriptCheckpayment").html(`
    <script type="text/javascript">
      checkout_after_load();
    </script>
    `);
    const loginInfo = this.fnGetLoginInfo();
    if (loginInfo != null) {
      const options = {
        "key": this.paymentGetwayKey,
        "amount": amount * 100,
        "currency": "INR",
        "name": "Casabazar",
        "description": "Payment",
        "image": this.hostURL + "/" + this.appSettings.white_logo,
        "handler": function (response: any) {
          handler(response);
        },
        "prefill": prefill,
        // "notes": {
        //   "address": "Razorpay Corporate Office"
        // },
        //  "theme": {
        //   "color": "#000"
        // },
      }
      return options;
    }
  }

  constructor() {
    if (localStorage.getItem(this.userCode) != null) {
      this.userToken = localStorage.getItem(this.userCode)?.toString();
    } else {
      if (this.userToken != null && this.userToken.trim() != "") {
        localStorage.setItem(this.userCode, this.userToken);
      }
    }
  }

  fnIdDect(input: string): number {
    try {
      let output = 0;
      output = parseInt(input) / this.encCode;
      return output;
    } catch (error) {
      return 0;
    }
  }

  fnIdEncy(input: number): string {
    try {
      let output = '';
      output = (input * this.encCode).toString();
      // output = this.ency((input * this.encCode).toString());
      return output;
    } catch (error) {
      return '';
    }
  }


  fnDect(input: string): string {
    try {
      let output = "";
      output = this.dect(input);
      return output;
    } catch (error) {
      return "";
    }
  }

  fnEncy(input: string): string {
    try {
      let output = '';
      output = this.ency((input).toString());
      return output;
    } catch (error) {
      return '';
    }
  }

  fnDefaultString(input?: string): string { return input == null ? "" : input; }
  fnDefaultNumber(input?: number): number { return input == null ? 0 : input; }
  fnDefaultBoolean(input?: boolean): boolean { return input == null ? false : input; }
  fnDefaultDate(input?: Date): Date { return input == null ? (new Date()) : input; }

  fnFileConverBase64(input: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      try {
        reader.readAsDataURL(input);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }
      catch (error) {
        reject(error);
      }
    });
  }

  fnConfirmationMessage(input: string = '', handler: Function): any {
    Swal.fire({
      title: 'Are you sure?',
      text: input,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        handler(true);
      } else if (result.isDismissed) {
        handler(false);
      }
    })
    // return window.confirm(input);
  }

  fnAlertMessage(input: string = '') {
    // window.alert(input);
    Swal.fire(input);
  }

  fnToastMessage(input: string = '', snackbar: MatSnackBar) {
    // window.alert(input);
    snackbar.open(input, 'CLOSE');
  }

  fnSetLoginInfo(output: any) {
    try {
      localStorage.setItem(this.userCode, this.ency(JSON.stringify(output)));
      this.userToken = this.userToken?.toString();
    } catch (error) { console.log("Error in fnSetLoginInfo " + error) }
  }

  fnSetCategoryInfo(output: any) {
    try {
      localStorage.setItem(this.categoryCode, this.ency(JSON.stringify(output)));
    } catch (error) { console.log("Error in fnSetCategoryInfo " + error) }
  }

  fnGetCategoryInfo(): any {
    var input: any = null;
    try {
      if (localStorage.getItem(this.categoryCode) != null) {
        var myData: any = localStorage.getItem(this.categoryCode)?.toString();
        input = JSON.parse(this.dect(myData));
      }
    } catch (error) { console.log("Error in fnGetCategoryInfo " + error); input = null; }
    return input;
  }

  fnSetVendorInfo(output: any) {
    try {
      localStorage.setItem(this.vendorCode, this.ency(JSON.stringify(output)));
    } catch (error) { console.log("Error in fnSetVendorInfo " + error) }
  }

  fnSetBrandInfo(output: any) {
    try {
      localStorage.setItem(this.brandCode, this.ency(JSON.stringify(output)));
    } catch (error) { console.log("Error in fnSetBrandInfo " + error) }
  }

  isVendor(): boolean {
    var input: boolean = false;
    try {
      if (localStorage.getItem(this.vendorCode) != null) {
        var jData = this.fnGetVendorInfo();
        var acc: boolean = jData.has_access;
        var acc_id: number = jData.cid;
        if (acc && acc_id != 0) {
          input = true;
        }
      }
    } catch (error) { console.log("Error in fnGetVendorInfo " + error); return false; }
    return input;
  }

  fnRemoveVendorInfo() {
    try {
      localStorage.removeItem(this.vendorCode);
    } catch (error) { console.log("Error in fnRemoveVendorInfo " + error) }
  }

  fnRemoveBrandInfo() {
    try {
      localStorage.removeItem(this.brandCode);
    } catch (error) { console.log("Error in fnRemoveBrandInfo " + error) }
  }

  fnGetVendorInfo(): any {
    var input: any = null;
    try {
      if (localStorage.getItem(this.vendorCode) != null) {
        var myData: any = localStorage.getItem(this.vendorCode)?.toString();
        input = JSON.parse(this.dect(myData));
      }
    } catch (error) { console.log("Error in fnGetVendorInfo " + error) }
    return input;
  }

  fnGetLoginInfo(): any {
    var input: any = null;
    try {
      if (this.userToken != null) {
        var myData: any = this.userToken?.toString();
        input = JSON.parse(this.dect(myData));
      }
    } catch (error) { console.log("Error in fnGetLoginInfo " + error) }
    return input;
  }

  fnGetBrandInfo(): any {
    var input: any = null;
    try {
      if (localStorage.getItem(this.brandCode) != null) {
        var myData: any = localStorage.getItem(this.brandCode)?.toString();
        input = JSON.parse(this.dect(myData));
      }
    } catch (error) { console.log("Error in fnGetBrandInfo " + error) }
    return input;
  }

  fnRemoveLoginInfo() {
    try {
      this.userToken = "";
      localStorage.removeItem(this.userCode);
      localStorage.removeItem(this.vendorCode);
      localStorage.removeItem(this.brandCode);
    } catch (error) { console.log("Error in fnRemoveLoginInfo " + error) }
  }

  fnGetToken(): string {
    var input: string = "";
    try {
      var profileData: any = null;
      if (this.userToken != null) {
        profileData = this.fnGetLoginInfo();
        input = profileData.token_type + " " + profileData.access_token;
      }
    } catch (error) { console.log("Error in fnGetToken " + error) }
    return input;
  }

  fnGetUserId(): number {
    var input: number = 0;
    try {
      var profileData: any = null;
      if (this.userToken != null) {
        profileData = this.fnGetLoginInfo();
        input = profileData.user.id;
      }
    } catch (error) { console.log("Error in fnGetUserId " + error) }
    return input;
  }

  fnGetCompanyId(): number {
    var input: number = 0;
    try {
      var comnpanyData: any = null;
      if (localStorage.getItem(this.vendorCode) != null) {
        comnpanyData = this.fnGetVendorInfo();
        input = comnpanyData.cid;
      }
    } catch (error) { console.log("Error in fnGetCompanyId " + error) }
    return input;
  }

  fnIsUserVerified(): boolean {
    var input: boolean = false;
    try {
      var comnpanyData: any = null;
      if (localStorage.getItem(this.vendorCode) != null) {
        comnpanyData = this.fnGetVendorInfo();
        input = comnpanyData.verified;
      }
    } catch (error) { console.log("Error in fnIsUserVerified " + error) }
    return input;
  }

  fnNotificationCount(): number {
    var input: number = 0;
    try {
      var comnpanyData: any = null;
      if (localStorage.getItem(this.vendorCode) != null) {
        comnpanyData = this.fnGetVendorInfo();
        input = comnpanyData.notification_count;
      }
    } catch (error) { console.log("Error in fnNotificationCount " + error) }
    return input;
  }

  fnGetBrandId(): number {
    var input: number = 0;
    try {
      var brandData: any = null;
      if (localStorage.getItem(this.brandCode) != null) {
        brandData = this.fnGetBrandInfo();
        input = brandData.id;
      }
    } catch (error) { console.log("Error in fnGetBrandId " + error) }
    return input;
  }

  private ency(input: any): any {
    var output: any;
    // output = new HomeModal().plus(input);
    output = btoa(input).split('=').join('');
    return output;
  }

  private dect(input: any): any {
    var output: any;
    // let con = new HomeModal().minus(input);
    let con = atob(input);
    output = con;
    return output;
  }
}

export class MainConfig extends Config { }
