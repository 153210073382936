import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { GetMapsComponent } from './get-maps/get-maps.component';
import { SetMapsComponent } from './set-maps/set-maps.component';
import { EncodeComponent } from './encode/encode.component'
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./forntend/forntend.module').then(m => m.ForntendModule) },
  { path: 'Vendor', loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule) },
  { path: 'setMap', component: SetMapsComponent },
  { path: 'ex/:id/:page', component: EncodeComponent },
  { path: 'getMap/:latitude/:longitude', component: GetMapsComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'test_error_page_url ', component: PageNotFoundComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }