<div class="container">

    <!-- <h1 class="mt-5">Select Location</h1> -->

    <div class="form-group mt-3">
        <h3 class="m-0"><label class="clsMyLabel">Enter address</label></h3>
        <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
            placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
            #search>
    </div>

    <agm-map (mapReady)="onMapReady($event)" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
        <agm-marker  [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
            (dragEnd)="markerDragEnd($event)"></agm-marker>
    </agm-map>

    <div class="row">
        <div class="col-md-6">
            <h3>Address: {{address}}</h3>
            <div>Latitude: {{latitude}}</div>
            <div>Longitude: {{longitude}}</div>
        </div>
        <div class="col-md-6 text-right">
            <button (click)="onSelect()" class="btn btn-primary mt-5 btn-lg">SELECT LOCATION</button>
        </div>
    </div>

</div>