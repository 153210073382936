import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Config } from '../forntend/models_response/config';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.css']
})
export class GetMapsComponent implements OnInit {

  title: string = 'AGM project';
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;


  constructor(
    private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.route.params
      .subscribe(
        (params: any) => {
          var latitude: any = new Config().fnDect(params['latitude']);
          var longitude: any = new Config().fnDect(params['longitude']);
          this.mapsAPILoader.load().then(() => {
            if (latitude != null && longitude != null) {
              this.latitude = latitude;
              this.longitude = longitude;
              this.zoom = 14;
              this.getGeoLocation();

            }
          });
        });
  }

  onMapReady(map?: google.maps.Map) {
    if (map)
      map.setOptions({
        streetViewControl: false
      });
  }

  getGeoLocation() {
    if (navigator.geolocation) {
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(this.latitude, this.longitude);
      let request: any = {
        latLng: latlng
      };
      geocoder.geocode(request, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          let result = results[0];
          if (result != null) {
            this.address = result.formatted_address;
            this.latitude = result.geometry.location.lat();
            this.longitude = result.geometry.location.lng();
          } else {
            alert("No address available!");
          }
        }
      });
    }
  }

}
