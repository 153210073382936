<div class="container mt-5">
    <agm-map (mapReady)="onMapReady($event)" [latitude]="latitude"  [longitude]="longitude" [zoom]="zoom">
        <agm-marker  [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false"></agm-marker>
    </agm-map>
    <div class="row">
        <div class="col-md-6">
            <h3>Address: {{address}}</h3>
            <div>Latitude: {{latitude}}</div>
            <div>Longitude: {{longitude}}</div>
        </div>
    </div>
</div>