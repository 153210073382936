export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDFML2tCVl51DlTlJE9JZMAjHJPdMDxuEM",
    authDomain: "casabazarupdate.firebaseapp.com",
    databaseURL: "https://casabazarupdate-default-rtdb.firebaseio.com",
    projectId: "casabazarupdate",
    storageBucket: "casabazarupdate.appspot.com",
    messagingSenderId: "319157205392",
    appId: "1:319157205392:web:58f1fd20bc7860cf7d1b15",
    measurementId: "G-YPZE4YZPFE"
  }
};
