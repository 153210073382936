import { NgModule } from '@angular/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { GetMapsComponent } from './get-maps/get-maps.component';
import { SetMapsComponent } from './set-maps/set-maps.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AgmCoreModule } from '@agm/core';
import { EncodeComponent } from './encode/encode.component'
import { ErrorComponent } from './error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    GetMapsComponent,
    SetMapsComponent,
    EncodeComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDG7aL4i9gxdPPYykqaseTfu4x2IFwvhoA',
      libraries: ['places']
    }),
    BrowserAnimationsModule,
  ],
  providers: [Meta,Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
