import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from '../forntend/models_response/config';

@Component({
  selector: 'app-encode',
  templateUrl: './encode.component.html',
  styleUrls: ['./encode.component.css']
})
export class EncodeComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    try {
      this.route.params
        .subscribe(
          (params: any) => {
            var id = atob(params['id'].split("").reverse().join(""));
            var page = params['page'];
            if (page.toLowerCase() == 'p') {
              this.router.navigate(['/profile', { queryParams: { u: this.convertId(parseInt(id)) } }]);
              console.log(id, page);
            }
            else if (page.toLowerCase() == 'b') {
              this.router.navigate(['/brand', { queryParams: { b: this.convertId(parseInt(id)) } }]);
              console.log(id, page);
            }
            else {
              this.router.navigate(['/']);
            }
          }
        );
    } catch (error) {
      this.router.navigate(['/']);
      console.log(error);
    }
  }

  convertId(id: number = 0): string {
    return new Config().fnIdEncy(id);
  }

}
